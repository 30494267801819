import Carousel from 'react-bootstrap/Carousel'; 
const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../public/img', false, /\.(png|jpe?g|svg)$/));


function Carrusel(){
    
    return(
        <Carousel>
            {images.map((src, index)=> (
            <Carousel.Item >
                
                <img src={src} alt={index}/>
                
            </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default Carrusel;